import React from "react"

const WIDTH = 1200
const HEIGHT = 675

export const rand = (max) => Math.round(max * Math.random())

export const generateBlob = (maxX, maxY) => {
    const randX = rand(maxX)
    const randY = rand(maxY)

    // console.log(`generated blob at ${randX}, ${randY}`)

    return {
        alive: true,
        selected:false,
        x: randX,
        y: randY,
    }
}

export const generateBlobs = (maxNum=50, maxX=WIDTH, maxY=HEIGHT) => {
    const num = rand(maxNum)
    const blobs = []

    for (let i = 0; i < num; ++i) {
        blobs.push(generateBlob(maxX, maxY))
    }

    return blobs
}

export const Blob = (props) => {
    const { x, y, alive, selected, onMouseDown, onMouseUp, id} = props
    // console.log(`this blob is at ${x}, ${y} and it is selected? ${selected}`)
    return alive ? (
        <div
            id={id}
            onMouseDown={onMouseDown}
            onMouseUp={onMouseUp}
            style={{
                position: "absolute",
                top: y,
                left: x,
                width: "40px",
                height: "40px",
                backgroundColor: "black",
                border: selected ? "2px solid blue" : null,
            }} />
    ) : null
}