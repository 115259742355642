import React, { useEffect, useState } from "react"
import { generateBlobs, Blob } from "../../libs/blobs"

/** ALERT - this is NOT optimized, it will be slow :'( if you have a ton of sprites **/

const MouseStates  = {
    NO_SELECT: 0,
    DRAG_SELECT: 1,
    BOX_SELECT: 2,
}

var mouseStartX = null
var mouseStartY = null
var mouseDx = null
var mouseDy = null
var mouseState = MouseStates.NO_SELECT
var blobs = generateBlobs(20)

// https://stackoverflow.com/questions/53024496/state-not-updating-when-using-react-state-hook-within-setinterval
export default (props) => {
    return <div>
            <div>
                oopsies, react got an update and broken oopsies no workyyy
            </div>
            <div>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/FveF-we6lcE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            Or even more demented: <a href="https://www.youtube.com/watch?v=eWTtEkRz4fM">oopsie woopsie</a>
            <div>
            </div>
        </div>
    // windowing and mouse
    // const [winWidth, setWinWidth] = useState(window.innerWidth)
    // const [winHeight, setWinHeight] = useState(window.innerHeight)

    // // const [mouseStartX, setMouseStartX] = useState(null)
    // // const [mouseStartY, setMouseStartY] = useState(null)
    // // const [mouseDx, setMouseDx] = useState(null)
    // // const [mouseDy, setMouseDy] = useState(null)

    // // const [mouseState, setMouseState] = useState(MouseStates.NO_SELECT)

    // // sprites
    // // const [blobs, ] = useState(generateBlobs(3))

    // // blobs.forEach((blob, index) => {
    // //     if (blob.selected) {
    // //         console.log(`blob at ${index} is located at ${blob.x} + ${mouseDx}, ${blob.y} + ${mouseDy}`)
    // //     }  
    // // })

    // const getX = (blob) => blob.x + (mouseState === MouseStates.DRAG_SELECT && blob.selected ? mouseDx : 0)
    // const getY = (blob) => blob.y + (mouseState === MouseStates.DRAG_SELECT && blob.selected ? mouseDy : 0)

    // const getW = (index) => document.getElementById(`${index}`).clientWidth
    // const getH = (index) => document.getElementById(`${index}`).clientHeight


    // // constructor for windowing etc..
    // useEffect(() => {
    //     document.title = `Box and Drag`

    //     // global event listeners a bit easier for detecting shit
    //     const eventListeners = [
    //         [`resize`, updateWindowDimensions], 
    //         [`mousemove`, handleMouseMove],
    //         [`mousedown`, handleMouseDown],
    //         [`mouseup`, handleMouseUp],
    //     ]

    //     eventListeners.forEach((params) => {
    //         const [type, listener] = params
    //         window.addEventListener(type, listener)
    //     })

    //     return () => {
    //         eventListeners.forEach((params) => {
    //             console.log(`removing ?!`)
    //             const [type, listener] = params
    //             window.removeEventListener(type, listener)
    //         })
    //     }
    // /* eslint-disable react-hooks/exhaustive-deps */
    // }, [])

    // const updateWindowDimensions = () => {
    //     setWinWidth(window.innerWidth)
    //     setWinHeight(window.innerHeight)
    // }

    // const handleMouseMove = (evt) => {
    //     const [mouseX, mouseY] = [evt.clientX, evt.clientY]
    //     console.log(`mouse move, mouseDx ${mouseDx}, mouseDy ${mouseDy}, mouseStartX ${mouseStartX}, mouseStartY ${mouseStartY}`)

    //     if (mouseDx !== null && mouseDy !== null && mouseStartX !== null && mouseStartY !== null) {
    //         console.log(`started at ${mouseStartX} but now on ${mouseX} with dx as ${mouseDx}`)

    //         // setMouseDx(mouseX - mouseStartX)
    //         // setMouseDy(mouseY- mouseStartY)
    //         mouseDx = mouseX - mouseStartX
    //         mouseDy = mouseY - mouseStartY

    //         // can be made faster by doing it all at once in the end, but I want them
    //         // to highlight dynamically basically :'(
    //         if (mouseState === MouseStates.BOX_SELECT) {
    //             // console.log(`box select`)
    //             blobs.forEach((blob) => {
    //                 // fix this it only gets the corners
    //                 if ((blob.x >= mouseStartX && blob.x <= mouseX) && (blob.y >= mouseStartY && blob.y <= mouseY)) {
    //                     blob.selected = true
    //                 } else {
    //                     blob.selected = false
    //                 }
    //             })
    //         } else if (mouseState === MouseStates.DRAG_SELECT) {
    //             blobs.forEach(blob => {
    //                 if (blob.selected) {
    //                     blob.x += mouseDx
    //                     blob.y += mouseDy
    //                 }
    //             })
    //         }
    //     }
    // }

    // const handleMouseDown = (evt) => {
    //     const [mouseX, mouseY] = [evt.clientX, evt.clientY]
        
    //     // chooses the first one, which should be ok since that's the order that they display in
    //     const blobSelected = blobs.reduce((accumulator, blob, index) => {
    //         const [blobW, blobH] = [getW(index), getH(index)]
    //         const [top, bottom, left, right] = [blob.y, blob.y + blobH, blob.x, blob.x + blobW]
    //         // console.log(`top bottom left right is ${top}, ${bottom}, ${left}, ${right} with acc ${accumulator}`)
    //         // console.log(`mouse y,x is ${mouseY}, ${mouseX}\n`)
    //         const yFits = mouseY >= top && mouseY <= bottom
    //         const xFits = mouseX >= left && mouseX <= right
    //         // console.log(`y fits, x fits is ${yFits}, ${xFits}`)
    //         if (accumulator === null && xFits && yFits) {
    //             // console.log(`IT FIT and index is ${index}`)
    //             return index
    //         } else {
    //             return accumulator
    //         }
    //     }, null)

    //     console.log(`OUTPUT IS ${blobSelected}`)

    //     // console.log(`blob selected was ${blobSelected}`)

    //     // console.log(`set mouse start x to ${mouseX}`)
    //     // set the parameters so we know how much to drag by
    //     // setMouseStartX(prevStartX => mouseX)
    //     // setMouseStartY(prevStartY => mouseY)
    //     // setMouseDx(prevDx => 0)
    //     // setMouseDy(prevDy => 0)
    //     mouseStartX = mouseX
    //     mouseStartY = mouseY
    //     mouseDx = 0
    //     mouseDy = 0
    //     console.log(`so in theory with ${mouseX}, ${mouseY}, ${0}, ${0}...`)
    //     console.log(`just set mouse start X start y to ${mouseStartX}, ${mouseStartY} with mouse dx dy to ${mouseDx}, ${mouseDy}`)

    //     if (blobSelected !== null) {
    //         console.log(`blob selected`)
    //         blobs[blobSelected].selected = true   
            
    //         mouseState = MouseStates.DRAG_SELECT
    //         // setMouseState(MouseStates.DRAG_SELECT)
    //     } else {
    //         // setMouseState(MouseStates.BOX_SELECT)
    //         mouseState = MouseStates.BOX_SELECT

    //         // console.log(`why never here`)
    //         // for (const blob in blobs) {
    //         //     console.log(`blob is`)
    //         // }

    //         blobs.forEach((blob) => {
    //             blob.selected = false
    //             // console.log(`set to false`)
    //         })
    //     }

    //     // console.log(`${mouseStartX}, ${mouseDx}, ${mouseX}`)
    // }

    // const handleMouseUp = (evt) => {
    //     console.log(`mouse up`)
    //     if (mouseState === MouseStates.DRAG_SELECT) {
    //         blobs.forEach((blob) => {
    //             if (blob.selected) {
    //                 blob.x += mouseDx
    //                 blob.y += mouseDy
    //             }
    //         })
    //     }

    //     console.log(`setting shit to null`)
    //     // setMouseStartX(prevStartX => null)
    //     // setMouseStartY(prevStartY => null)
    //     // setMouseDx(prevDx => null)
    //     // setMouseDy(prevDy => null)
    //     mouseStartX = null
    //     mouseStartY = null
    //     mouseDx = null
    //     mouseDy = null

    //     // setMouseState(MouseStates.NO_SELECT)
    //     mouseState = MouseStates.NO_SELECT
    // }

    // // visualize
    // return blobs.map((blob, index) => {
    //     return <Blob 
    //             // necessary bs
    //             role="none"
    //             key={index} 

    //             // so we can get its offsetWidth
    //             id={index}

    //             // state information
    //             x={getX(blob)} 
    //             y={getY(blob)} 
    //             alive={blob.alive} 
    //             selected={blob.selected} 
    //         />
    //     })
}